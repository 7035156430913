import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import DataViewer from '~utils/DataViewer'
import { Logo } from '~components/Svg'
import Button from '~components/Button'
import Section from '~components/Section'
import resolveLink from '~utils/resolveLink'
import { useLocation } from '@reach/router'
import Tile from '~components/Tile'
import Transition from '~components/Transition'
import { mobile, tablet, maxMenu } from '~styles/global'
import SlideDown from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { ChevronDown, Rarr, Cross, Search, Mob } from '~components/Svg'
import SanityLink from '~components/SanityLink'
import Headroom from 'react-headroom'
import useBreakpoint from '~utils/useBreakpoint'
import RichText from '~components/RichText'
import SearchBar from '~components/SearchBar'
import { useSiteState } from '~context/siteContext'
import { Bubble, BubbleMobile, Globe, GlobeMobile } from './Svg'

const Header = ({ className }) => {
	const { sanityNavigation: nav, contact } = useStaticQuery(graphql`
	{
		sanityNavigation {
			getSupport{
				...menuSection
			}
			learn{
				...menuSection
			}
			getInvolved{
				...menuSection
			}
			about{
				...menuSection
			}
			headerSlimText: _rawHeaderSlimText(resolveReferences: {maxDepth: 3})
			headerSlimMobile: _rawHeaderSlimTextMobile(resolveReferences: {maxDepth: 3})
			headerScrolledText: _rawHeaderSlimText(resolveReferences: {maxDepth: 3})
		}
		contact: sanityPage(slug: {current: {eq: "contact"}}) {
			title
		}
	}`)

	const menuItems = ['getSupport', 'learn', 'getInvolved', 'about']

	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
	const [pinned, setPinned] = useState(true)
	const headroomRef = useRef()
	const { isMobileMenu } = useBreakpoint()

	useEffect(() => {
		if(pinned && headroomRef.current){
			headroomRef.current.pin()
		}
	}, [pinned])

	const [siteState, setSiteState] = useSiteState()

	// reset the menu on navigate
	const location = useLocation()
	useEffect(() => {
		window.setTimeout(() => {
			setPinned(true)
			setActiveSection(null)
			setMobileMenuOpen(false)
			setSiteState(prevState => ({...prevState, searchOpen: location.pathname === '/search'}))
		}, 0)
	}, [location])

	// reset the menu wehn it moves between desktop / mobile
	useEffect(() => {
		window.addEventListener('resize', () => {
			if(window.innerWidth <= 1150){
				setActiveSection(null)
			} else {
				setMobileMenuOpen(false)
			}
		})
	}, [])

	const [activeSection, setActiveSection] = useState(null)
	const active = nav[activeSection]

	useEffect(() => {
		if(activeSection && siteState.searchOpen){
			setActiveSection(null)
		}
	}, [siteState.searchOpen])

	useEffect(() => {
		if(activeSection){
			setSiteState(prev => ({...prev, searchOpen: false}))
		}
	}, [activeSection])

	return(
		<>
			{(activeSection || mobileMenuOpen)&&
				<Global styles={css`
					body, html { overflow-y: hidden; }
				`}/>
			}
			<PreHeader 
				onPin={() => setPinned(true)}
				onUnpin={() => setPinned(false)}
				ref={headroomRef}
				className='b2'
			>
				<Message>
					<RichText content={isMobileMenu ? nav.headerSlimMobile : nav.headerSlimText} />
					<Mob />
				</Message>
			</PreHeader>
			<Wrap className={className} dark={mobileMenuOpen} pinned={pinned}>
				<Link to='/'>
					<HeaderLogo />
				</Link>
				<ScrollTransition elementKey={pinned ? 'helpline' : 'menu'} duration={0.45}>
					{!pinned && !isMobileMenu ?
						<ScrolledMessage>
							<Message>
								<RichText content={nav.headerScrolledText}/>
								<Mob />
							</Message>
							<DesktopHamburger 
								className="h5" 
								onClick={() => setPinned(true)}
								light={mobileMenuOpen}
								desktop
							>
								<span>Menu</span>
								<div>
									<div />
									<div />
									<div />
								</div>
							</DesktopHamburger>
						</ScrolledMessage> 
						:
						<MainMenu className='h5'>
							{menuItems.map(section => 
								<NavButton 
									key={section} 
									icon='ChevronDown'
									flipped={activeSection === section}
									onClick={() => setActiveSection(activeSection !== section ? section : null)}
									css={css`
										background: ${section === activeSection ? 'var(--bteal40)' : ''};
										&:hover{
											background: ${section === activeSection ? 'var(--bteal40)' : ''};
										}
									`}
								>
									{nav[section].title}
								</NavButton>
							)}
							<NavButton key='get-in-touch'><Link to='/contact'>{contact.title}</Link></NavButton>
							<NavButton key='globe'>
								<SvgLink to='/get-support/support-in-languages-other-than-english'>
									<Globe/>
								</SvgLink>
							</NavButton>
							<NavButton key='chat'>
								<SvgLink to='/chat'>
									<Bubble/>
								</SvgLink>
							</NavButton>
							<NavButton 
								key='search' 
								icon='Search' 
								onClick={() =>setSiteState({...siteState, searchOpen: !siteState.searchOpen})}>
									Search
							</NavButton>	
						</MainMenu>
					}
					
				</ScrollTransition>
				<HamburgerButton 
					className="h5" 
					onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
					light={mobileMenuOpen}
				>
					<span>{mobileMenuOpen ? 'Close' : 'Menu'}</span>
					<div>
						{!mobileMenuOpen ?
							<>
								<div />
								<div />
								<div />
							</> :
							<Cross />
						}
						
					</div>
				</HamburgerButton>
				<SearchBar pinned={pinned} />
			</Wrap>
			{activeSection &&
			<DesktopMenu pinned={pinned}>
				<Transition elementKey={activeSection} duration={0.45}>
					<DesktopMenuSection>
						<NavColumn>
							<p className='b2'>{active.subtitle}</p>
							{active.primaryLinks.map(item => 
								<>
									<SanityLink link={item.link} className="h4" key={item.text}>
										<span>{item.text}</span>
									</SanityLink>
									<br/>
								</>
							)}
						</NavColumn>
						<NavColumn>
							{active.popularLinks.length > 0 &&
							<>
								<p className='b2'>Popular Links</p>
								{active.popularLinks.map(item => (
									<div key={item._key}>
										<SanityLink link={item.link} ><span>{item.text}</span></SanityLink>
									</div>
								))}
							</>	
							}
						</NavColumn>
						
						{active?.featured &&
							<Featured>
								<Tile content={active?.featured} hideText/>
							</Featured>
						}	
					</DesktopMenuSection>		
				</Transition>
			</DesktopMenu>
			}
			<MobileMenu open={mobileMenuOpen}>
				{menuItems.map(section => 
					<MobileMenuSection key={section} css={css`
						background: ${activeSection === section ? 'var(--vdteal)' : 'none'};
					`}>
						<MobileMenuButton 
							className='h4' 
							onClick={() => setActiveSection(activeSection !== section ? section : null)}
							open={activeSection === section}
						>
							<span>
								{nav[section].title}
							</span>
							<ChevronDown />
						</MobileMenuButton>
						<SlideDown>
							{activeSection === section &&
								<div>
									{nav[section].primaryLinks.map(item => 
										<SanityLink key={item._key} link={item.link} className='h5'>
											<Rarr/>
											<span>{item.text}</span>
										</SanityLink>
									)}
								</div>
							}
						</SlideDown>
					</MobileMenuSection>
				)}
				<MobileMenuSection key='get-in-touch'>
					<Link className="h4" to='/contact'>
						{contact.title}
					</Link>
				</MobileMenuSection>
				<MobileMenuSection key='get-in-touch'>
					<Link key='search' className="h4" to='/search'>
						<span>
							Search
						</span>
						<Search/>
					</Link>	
				</MobileMenuSection>
				<Icons>
					<SvgLinkMobile to='/get-support/support-in-languages-other-than-english'>
						<GlobeMobile />
					</SvgLinkMobile>
					<SvgLinkMobile to='/chat'>
						<BubbleMobile />
					</SvgLinkMobile>
				</Icons>
				
			</MobileMenu>
			{/* <DataViewer data={nav} name="nav"/> */}
		</>
	)
}

const SvgLink = styled(Link)`
	display: inline-block;
	transform: translateY(5px);
	svg {
		width: 24px;
		height: 24px;
	}
`

const SvgLinkMobile = styled(Link)`
	display: inline-block;
	transform: translateY(5px);
	margin-right: var(--s);
	svg {
		fill: var(--white);

		width: 42px;
		height: 42px;
	}
`

const Icons = styled.div`
	margin-top: var(--s);
	margin-left: var(--s);
`

const Wrap = styled.header`
  position: fixed;
  top: 0;
  right: 0;
	left: 0;
  z-index: 100;
	height: 100px;
	background: ${props => props.dark ? 'var(--dteal)' : 'var(--beige)'};
	display: flex;
	align-items: center;
	padding: 0 var(--l) 0 var(--xs);
	justify-content: space-between;
	transition: background-color 0.2s, transform 0.2s;
	transform: ${props => props.pinned ? 'translateY(50px)' : 'none'};
	&:after{
		content: "";
		display: block;
		position: absolute;
		height: 300px;
		top: -300px;
		left: 0;
		right: 0;
		bottom: 0;
		background: ${props => props.dark ? 'var(--dteal)' : 'var(--beige)'};
	}
	${maxMenu}{
		height: 80px;
		padding: 0 0 0 var(--xs);
	}
`

const HeaderLogo = styled(Logo)`
	width: 183px;
	${maxMenu}{
		width: 134px;
	}
`

const MainMenu = styled.nav`
	text-align: right;
	height: 100px;
	${maxMenu}{
		display: none;
	}
`

const NavButton = styled(Button)`
	font-size: inherit;
	text-transform: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	min-width: 0;
	padding: var(--xs);
	height: 100%;
	&:last-child{
		padding-right: 0;
	}
	svg{
		display: inline-block;
	}
	@media(max-width: 1220px){
		padding: var(--s) var(--xs);
	}
`

const DesktopMenu = styled.div`
	background: var(--bteal40);
	position: fixed;
	top: ${props => props.pinned ? '150px' : '100px'};
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 101;
	overflow-y: scroll;
	${maxMenu}{
		display: none;
	}
`

const DesktopMenuSection = styled(Section)`
	padding-top: var(--l);
	padding-bottom: var(--l);
`

const NavColumn = styled.nav`
	grid-column: span 4;
	p{
		margin-bottom: var(--xs);
	}
	.h4{
		display: inline-block;
		margin-bottom: var(--m);
		span {
			border-bottom: 2px solid transparent;
		}
		&:hover{
			span {
				border-bottom: 2px solid;
			}
		}
	}
	div{
		margin-bottom: var(--xs);
	}
	a:not(.h4){
		&:hover{
			text-decoration: underline;
		}
	}
`

const Featured = styled.div`
	grid-column: span 4;
`

const HamburgerButton = styled.button`
	display: none;
	align-items: center;
	line-height: 0;
	padding: var(--s);
	color: ${props => props.light ? 'var(--beige)' : 'inherit'};
	transition: color 0.3s;
	&:active{
		/* transform: translateY(2px); */
	}
	${maxMenu}{
		display: flex;
	}
	span{
		display: inline-block;
		transform: translateY(-0.5px);
	}
	> div {
		width: 28px;
		height: 20px;
		display: inline-flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: var(--xs);
		div {
			height: 4px;
			width: 100%;
			transition: background-color 0.3s;
			background: ${props => props.light ? 'var(--beige)' : 'var(--dteal)'};
		}
	}
`

const DesktopHamburger = styled(HamburgerButton)`
	display: flex;
	padding-right: 0;
`

const MobileMenu = styled.div`
	position: fixed;
	top: 80px;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--dteal);
	z-index: 80;
	display: none;
	opacity: ${props => props.open ? '1' : '0'};
	transition: opacity 0.2s;
	pointer-events: ${props => props.open ? 'all' : 'none'};
	padding-top: var(--xl);
	overflow-y: scroll;
	padding-bottom: var(--m);
	${maxMenu}{
		display: block;
	}
`

const MobileMenuButton = styled.button`
	display: flex;
	align-items: center;
	width: 100%;
	padding: var(--xxs) var(--s);
	${mobile}{
		justify-content: space-between;
	}
	span{
		display: block;
	}
	svg {
		width: 16px;
		color: var(--bteal60);
		margin: 0 var(--xxxs) 0 var(--xs);
		transition: transform 0.3s;
		transform: ${props => props.open ? 'rotate(180deg)' : 'none'};
	}
`

const MobileMenuSection = styled.div`
	color: var(--white);
	transition: background-color 0.3s;
	> a {
		display: flex;
		padding: var(--xxs) var(--s);
		position: relative;
		align-items: center;
		${mobile}{
			justify-content: space-between;
		}
		svg{
			width: 24px;
			height: 24px;
			margin-left: var(--xs);
			color: var(--bteal);
		}
	}
	div {
		a {
			display: flex;
			padding: var(--xxs) var(--s);
			align-items: center;
			&:last-child{
				padding-bottom: var(--m);
			}
			&:first-child{
				padding-top: var(--s);
			}
			span{
				display: block;
			}
			svg {
				width: 18px;
				height: 14px;
				color: var(--bteal60);
				margin-right: var(--s);
				flex-shrink: 0;
			}
		}
	}
`

const PreHeader = styled(Headroom)`
> div {
	z-index: 110 !important;
	background: var(--dteal);
	height: 50px;
	color: var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
}
`

const ScrolledMessage = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`

const Message = styled.div`
	margin-bottom: 0;
	flex-grow: 1;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 var(--xxs);
	p{
		margin-bottom: 0;
	}
	svg{
		width: 12px;
		margin-left: var(--xs);
	}
`

const ScrollTransition = styled(Transition)`
	flex-grow: 1;
`



Header.propTypes = {
	className: PropTypes.string,
}

export default Header
